@import "bootstrap/bootstrap";
@import "font-awesome/font-awesome";
@import "clean-blog/clean-blog";

@import "//fonts.googleapis.com/css?family=Lora:400,700,\
400italic,700italic";
@import "//fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,\
  600italic,700italic,800italic,400,300,600,700,800'";

.intro-header .post-heading .meta a,
article a {
  text-decoration: underline;
}

h2 {
  padding-top: 22px;
}
h3 {
  padding-top: 15px;
}

h2 + p, h3 + p, h4 + p {
  margin-top: 5px;
}

// Adjust position of captions
.caption-title {
  margin-bottom: 5px;
}
.caption-title + p {
  margin-top: 0;
}

// Change the styling of dt/dd elements
dt {
  margin-bottom: 5px;
}
dd {
  margin-left: 30px;
  margin-bottom: 10px;
}
